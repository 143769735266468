import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const Filters = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;

  @media (max-width: 768px) {
    top: 20px;
  }

  z-index: 1000;

  & ol {
    margin: 0;
    padding: 0;

    & li {
      margin: 0 0 10px 0;

      & a {
        border: 1px solid rgba(253, 252, 252, 0.8);
        box-sizing: border-box;
        border-radius: 1px;

        display: inline-block;

        padding: 7px 10px 6px 10px;

        color: rgba(253, 252, 252, 0.8);

        text-transform: uppercase;

        font-family: "LDNBloomsburyOldStyle-Bold", Times, "Times New Roman",
          serif;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;

        background-color: transparent;
        transition: 250ms background-color ease;

        &:hover,
        &.active {
          background-color: #fff;
          color: rgba(29, 29, 27, 1);
        }

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 14px;

          padding: 6px 6px 4px 6px;
        }
      }

      &.film-filter {
        & a {
          background-color: #fff;
          color: rgba(29, 29, 27, 1);

          &:hover {
            border: 1px solid rgba(253, 252, 252, 0.8);
            color: rgba(253, 252, 252, 0.8);
            background-color: transparent;
          }
        }
      }
    }
  }
`;

const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-");

export const FilteredProduction = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicVideo {
        edges {
          node {
            tags
          }
        }
      }
    }
  `);

  const tags = Array.from(
    new Set(data.allPrismicVideo.edges.map(video => video.node.tags).flat())
  )
    .sort()
    .map((tag, index) => (
      <li key={`product_page_tag_${index}`}>
        <Link to={`/film/${toKebabCase(tag)}`}>{tag}</Link>
      </li>
    ));

  return (
    <Filters>
      <ol>
        <li className="film-filter">
          <Link to={`/directors/`}>Directors</Link>
        </li>
        {tags}
      </ol>
    </Filters>
  );
};
