import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { AllDirectorVideos } from "../components/directors/all-directors-videos";
import { AllDirectorsGalleries } from "../components/directors/all-directors-galleries";
import { FilteredProduction } from "../components/filters/filtered-production";

const Page = styled.div``;

const IntroTextContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  text-align: center;

  margin: 0 auto;
  padding: 65px 20px;

  & p {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.01em;

    color: rgba(253, 252, 252, 0.8);

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  & button {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    margin: 0 auto;
    padding: 5px 10px;

    color: rgba(250, 251, 246, 0.5);
  }

  @media (max-width: 900px) {
    padding: 40px 20px 35px 20px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 680px;

  margin: 0 auto;
  padding: 155px 20px 140px 20px;

  text-align: left;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;

  color: #fcfbfc;

  @media (max-width: 1300px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ProductionContent = ({ data, setPageType, location }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    setPageType("directors");
  }, []);

  return (
    <>
      <Helmet
        title={`Film – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Film – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Film – Park Village`,
          },
        ]}
      />

      <Page>
        <AllDirectorsGalleries
          data={data.prismicDirectors.data.full_width_carousel}
          location={location}
          videoLocation={`production`}
        />

        <IntroTextContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicDirectors.data.intro_text.html,
            }}
          />

          <button
            className="read-more-button"
            onClick={() =>
              document.querySelector("#read-more").scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          >
            Read More
          </button>
        </IntroTextContainer>

        <AllDirectorVideos
          data={data.prismicDirectors.data.directors}
          location={location}
          videoLocation={`production`}
        />

        <TextContainer>
          <div
            className="biography-text"
            id="read-more"
            dangerouslySetInnerHTML={{
              __html: data.prismicDirectors.data.text.html,
            }}
          />
        </TextContainer>

        <AllDirectorVideos
          data={data.prismicDirectors.data.videos}
          location={location}
          videoLocation={`production`}
        />

        <FilteredProduction />
      </Page>
    </>
  );
};

const Production = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <ProductionContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(Production);

export const query = graphql`
  {
    prismicDirectors {
      prismicId
      data {
        text {
          html
        }
        intro_text {
          html
        }
        full_width_carousel {
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    title
                    description
                    embed_url
                    width
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    url
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
                uid
              }
            }
          }
        }
        directors {
          video_size
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    url
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
        videos {
          video_size
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    url
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
